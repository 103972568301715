.layout-breadcrumb {
  background-color: var(--surface-a);
  border-bottom: 1px solid var(--surface-d);
  padding: 0.5rem 2rem;
  height: 35px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;

      a {
        line-height: 1;
        color: var(--text-secondary);
        text-transform: capitalize;
        text-decoration: none;
      }

      &:last-child {
        a {
          font-weight: 700;
        }
      }

      .chevron {
        margin: 0 0.5rem;
      }
    }
  }
}

#breadcrumbs {
  background-image: var(--banner-background-image);
  background-position: right 2% center, left top;
  background-repeat: no-repeat, repeat;
  background-size: 120px, cover;
}
