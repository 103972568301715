.occ-login {
  .occ-login-card {
    #email.p-inputtext.p-component {
      margin-bottom: 2px;
    }

    .p-inputtext.p-component.p-password-input {
      margin-bottom: 2px;
    }
  }
}
