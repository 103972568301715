html {
  font-size: 1rem;
  height: 100%;
}

body {
  font-family: var(--font-family);
  background-color: var(--surface-b);
  color: var(--text-color);
  padding: 0;
  margin: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.documentation i {
  background-color: var(--surface-a);
  color: #2196f3;
  font-family: Monaco, courier, monospace;
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 4px;
  letter-spacing: 0.5px;
  border-radius: 3px;
  font-weight: 600;
  margin: 0 2px;
}
