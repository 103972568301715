.introduction {
  background-image: url("../../images/bg-hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 115px);
  margin: -2rem -2rem 2rem -2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .introduction-content {
    padding: 4rem;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.9);
    text-align: center;

    .introduction-title {
      font-size: 3rem;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 0.5rem;
    }

    .introduction-subtitle {
      font-size: 3rem;
      font-weight: 700;
      color: var(--primary-color);
      line-height: 1;
      margin-bottom: 1.5rem;
    }

    p {
      max-width: 600px;
      font-size: 1.5rem;
      margin: 0 auto 1.5rem auto;
      line-height: 1.5;
      text-align: center;
      color: var(--text-secondary-color);
    }

    button {
      border-radius: 35px;
    }
  }
}

.layout-wrapper-dark {
  .introduction {
    background-image: url("../../images/bg-hero-dark.png");

    .introduction-content {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }
}

@media screen and (max-width: 576px) {
  .introduction {
    .introduction-content {
      padding-left: 2rem;
      padding-right: 2rem;

      .introduction-title,
      .introduction-subtitle {
        font-size: 2rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }
}
